/**
 * Returns file name of imageFile
 * 
 * @param {File} imageFile 
 * @returns {string} file name
 */
 export const getImageName = (imageFile) => {
    return imageFile.name.split('.').slice(0, -1).join('.');
}

/**
 * Resizes image so that width or height is maxImgWidthOrHeight
 * 
 * @param {File} imageFile 
 * @param {number} maxImgWidthOrHeight 
 * @returns {Promise<File>} resized image file
 */
export const resizeImage = async (imageFile, maxImgWidthOrHeight) => {
    return new Promise(resolve => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        //Get image size from imageFile
        let image = new Image();
        image.src = URL.createObjectURL(imageFile);

        image.onload = () => {

            if (image.width === 0 || image.height === 0) {
                console.error("image width and height needs to be greater than 0!");
                return;
            }

            if (image.width > image.height) {
                canvas.width = maxImgWidthOrHeight;
                canvas.height = maxImgWidthOrHeight * (image.height / image.width);
            } else {
                canvas.height = maxImgWidthOrHeight;
                canvas.width = maxImgWidthOrHeight * (image.width / image.height);
            }

            if (ctx !== null)
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

            const imgUrl = canvas.toDataURL('image/jpeg');
            fetch(imgUrl).then(response => response.blob()).then(blob => {
                const resizedImageFile = new File([blob], imageFile.name, {type: "image/jpeg"});
                resolve(resizedImageFile);
            })
        }

        image.remove();
    })
}

/**
 * Starts download of blob with filename
 * 
 * @param {Blob} blob 
 * @param {string} filename 
 */
export const downloadBlob = (blob, filename) => {
    var a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    a.click();
    URL.revokeObjectURL(a.href);
}

/**
 * Scales bpmnXml bounds and waypoints with scaleFactor
 * 
 * @param {string} bpmnXml 
 * @param {number} scaleFactor 
 * @returns {string} scaledBpmnXml
 */
export const scaleBpmnXml = (bpmnXml, scaleFactor) => {
    let xmlDocument = new DOMParser().parseFromString(bpmnXml, "text/xml");
    let bounds = xmlDocument.getElementsByTagName("omgdc:Bounds");
    let waypoints = xmlDocument.getElementsByTagName("omgdi:waypoint");

    //update bounding boxes
    for (let bound of bounds) {

        bound.setAttribute("x", bound.getAttribute("x") * scaleFactor);
        bound.setAttribute("y", bound.getAttribute("y") * scaleFactor);
        bound.setAttribute("width", bound.getAttribute("width") * scaleFactor);
        bound.setAttribute("height", bound.getAttribute("height") * scaleFactor);
    }

    //update waypoints
    for (let waypoint of waypoints) {

        waypoint.setAttribute("x", waypoint.getAttribute("x") * scaleFactor);
        waypoint.setAttribute("y", waypoint.getAttribute("y") * scaleFactor);
    }

    let scaledBpmnXml = new XMLSerializer().serializeToString(xmlDocument.documentElement);
    return scaledBpmnXml;
}