import {useDropzone} from 'react-dropzone';

const FileDropzone = ({ 
    onDrop, 
    maxFiles, 
    dragActiveText, 
    fileValue,
    defaultValue,
    accept,
    onDropRejected
}) => {

    const { getRootProps, getInputProps, isDragActive} = useDropzone({
        maxFiles: maxFiles, 
        onDrop: onDrop, 
        accept: accept,
        onDropRejected: onDropRejected
    });

    return (
        <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>{dragActiveText}</p> 
                :
                    (fileValue ? 
                        <p><i className="fa fa-file-image-o" /> {fileValue.name}</p> 
                    :
                        <p><i className="fa fa-paperclip" /> {defaultValue}</p>)
            }
        </div>
    )
}

export default FileDropzone;