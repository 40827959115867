import exampleImage1 from './resources/example_image1.jpg';
import exampleImage2 from './resources/example_image2.jpg';
import exampleImage3 from './resources/example_image3.jpg';

export const exampleImageUrls = [exampleImage1, exampleImage2, exampleImage3];

export const baseUrl = 'https://sketch2bpmn.informatik.uni-mannheim.de';

export const paletteWidth = 93; //in px

export const maxImgWidthOrHeight = 1333; //in px

export const defaultImageBrightness = "120"; //in %

export const diagramFontSize = '12px';

export const diagramStrokeColor = 'black';