import {
    Container,
    Navbar,
    Nav
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const Header = () => {
    return (
        <Navbar bg="dark" variant="dark"> 
            <Container fluid>
                <Navbar.Brand>
                    Sketch2Process
                </Navbar.Brand>
                <Nav className='me-auto'>
                    <LinkContainer to="/">
                        <Nav.Link>Demo</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/about">
                        <Nav.Link>About</Nav.Link>
                    </LinkContainer>
                </Nav>
            </Container> 
        </Navbar>
    )
}

export default Header;