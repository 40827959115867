import React from 'react';
import $ from 'jquery';

const About = () => {

    $('#bpmn-container').hide();
    return (
        <div>
            <h4>The Sketch2Process approach</h4>
            <p>
                Sketch2Process is an end-to-end sketch recognition approach for BPMN models that is currently under submission. 
                Sketch2Process uses a neural network-based architecture to recognize the shapes, edges, and labels of hand-drawn BPMN models. 
                We published a preceding approach, <a href="https://hanvanderaa.com/wp-content/uploads/2021/03/CAISE2021-Sketch2BPMN-Automatic-recognition-of-hand-drawn-BPMN-models.pdf">Sketch2BPMN</a>, 
                at the CAiSE 2021 conference. Our new approach considerably improves upon its predecessor in terms of scope and quality. 
                In terms of scope, Sketch2Process is the first approach to include the recognition of BPMN labels. 
                In terms of quality, Sketch2Process substantially improves the recognition accuracy of previous works.
            </p>
            <br />
            <h4>Explanation of the dataset the model was trained on</h4>
            <p>
                The model was trained on <a href="https://github.com/dwslab/hdBPMN">hdBPMN</a>, the hand-drawn BPMN dataset published jointly with our paper. 
                It has therefore only seen hand-drawn diagram images with the following properties:
            </p>
            <ol>
                <li> drawn with pen on paper </li>
                <li> digitalized with a scanner or reasonable smartphone </li>
                <li> only contains the BPMN shapes and edges listed below: <br />
                    <ul>
                    <li> <i>activity</i>: task, collapsed subprocess </li>
                    <li> <i>events</i>: untyped, message, and timer</li>
                    <li> <i>gateways</i>: exclusive, parallel, and event-based </li>
                    <li> <i>resources</i>: horizontal pool </li>
                    <li> <i>data elements</i>: data object, data store </li>
                    <li> <i>edges</i>: sequence flow, message flow, data association </li>
                    </ul>
                </li>
            </ol>
            <div>This means the model might not recognize:</div>
            <ul>
                <li> computer-generated diagrams </li>
                <li> flowchart symbols: e.g. decision diamond shape </li>
                <li> background: whiteboard or brown paper (might work but not evaluated yet) </li>
                <li> haptic elements: e.g. post-its to model tasks or gateways </li>
                <li> captured images: e.g. picture taken from large distance or with significant perspective distortion, i.e. camera not frontal-parallel to sketching surface </li>
            </ul>
        </div>
    )
}

export default About;