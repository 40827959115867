import $ from 'jquery';

import {
    ListGroup,
    Form,
    Button,
    ButtonGroup,
    Spinner,
    Alert,
    Col,
    Row
} from 'react-bootstrap';

import FileDropzone from './FileDropzone';

const Homepage = ({ 
    imageFile, 
    updateImageFile, 
    onSubmitImage, 
    bpmnFileReceived, 
    downloadBpmnFile, 
    imageSubmitted, 
    wrongInputType, 
    setWrongInputType,
    exampleImages,
    imageBrightness,
    setBackgroundBrightness,
    downloadDrawIOFile
}) => {

    if (imageFile && bpmnFileReceived) {
        $('#bpmn-container').show();
    }

    return (
        <div>
            <p>
                Sketch2Process demo application that recognizes BPMN models drawn on paper.
                <br />
                Support for computer-generated diagrams will be integrated in the future. Further details about the approach can be found in the About section.
            </p>
            <p>
                Try out Sketch2Process by uploading your own image and clicking submit, or use one of the example images.
            </p>
            <br />
            <div className='example-images'>
                <h5>Example Images (click to select)</h5>
                <ListGroup horizontal>
                    {
                        exampleImages.map((exampleImage, index) => (
                            <ListGroup.Item key={index}>
                                <img className="example-img" alt="Sketched BPMN diagram" src={exampleImage.url} draggable="true" onClick={() => updateImageFile(exampleImage.file)} />
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
                <br />
            </div>
            <div className='upload-form'>
                <Form.Group>
                <Form.Label><i className="fa fa-upload" /> Upload BPMN image</Form.Label>
                <FileDropzone 
                    onDrop={files => updateImageFile(files[0])} 
                    maxFiles={1} 
                    accept="image/png, image/jpeg" 
                    dragActiveText="Drop here"
                    fileValue={imageFile}
                    defaultValue="Drop file here or click to browse"
                    onDropRejected={() => setWrongInputType(true)}
                />
                <br />
                <ButtonGroup>
                    <Button variant="dark" disabled={!(imageFile && !imageSubmitted)} onClick={onSubmitImage}>
                        {(imageSubmitted && !bpmnFileReceived) && <Spinner animation="border" size="sm"/>} Submit
                    </Button>
                    <Button variant="dark" disabled={!(imageFile && bpmnFileReceived)} onClick={downloadBpmnFile}>Download BPMN XML</Button>
                    <Button variant="dark" disabled={!(imageFile && bpmnFileReceived)} onClick={downloadDrawIOFile}>Download draw.io (beta)</Button>
                </ButtonGroup>
                </Form.Group>
            </div>
            <div>
                <br />
                {wrongInputType &&
                    <Alert variant="danger" onClose={() => setWrongInputType(false)} dismissible>Please submit an image file (.jpg, .jpeg., .png)</Alert> 
                }
                <br />
            
                {(imageFile && !bpmnFileReceived) &&
                    <div className='img-container'>
                        <h5>Preview Image</h5>
                        <img className="preview-img" alt="Preview" src={URL.createObjectURL(imageFile)} />
                    </div>
                }

                {(imageFile && bpmnFileReceived) &&
                    <div className='output-form'>
                        <h5>Overlay Image</h5>
                        <Form.Group as={Row}>
                        <Form.Label column sm="4" >Background image brightness: {imageBrightness + '%'}</Form.Label>
                        <Col sm="8">
                            <Form.Control 
                            className="form-range" 
                            type="range" 
                            min="0" 
                            max="400" 
                            step="1" 
                            defaultValue={imageBrightness} 
                            onChange={(e) => setBackgroundBrightness(e.target.value)} 
                            />
                        </Col>
                        </Form.Group>
                    </div>
                }
            </div>
        </div>
    )
}

export default Homepage;