import RuleProvider from 'diagram-js/lib/features/rules/RuleProvider';
import { Shape } from 'diagram-js/lib/model';

const PRIORITY = 2000;

/**
 * Custom Rules
 * Defines custom rules for BPMN modeling
 * 
 * @module CustomRules
 */
export default class CustomRules extends RuleProvider {

	//This function can be used to add new or adjust existing rules
	init() {

		this.addRule('shape.resize', PRIORITY, function(context) {
			let shape = context.shape;

			//true for a any Connection results in nothing other than an error message
			return (shape instanceof Shape);
		});
	}
}