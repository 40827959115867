
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import Header from './Header';
import Homepage from './Homepage';
import About from './About';
import PageNotFound from './PageNotFound';

import { 
    baseUrl,
    paletteWidth,
    maxImgWidthOrHeight,
    defaultImageBrightness,
    diagramFontSize,
    diagramStrokeColor,
    exampleImageUrls
} from './const';

import {
    getImageName,
    resizeImage,
    downloadBlob,
    scaleBpmnXml
} from './utils/fileOperations';

import BpmnModeler from 'bpmn-js/lib/Modeler';
import customBpmnModules from './bpmn-js-custom-modules';

const App = () => {

    const [imageFile, setImageFile] = useState(null);
    const [bpmnFileReceived, setBpmnFileReceived] = useState(false);
    const [imageSubmitted, setImageSubmitted] = useState(false);
    const [wrongInputType, setWrongInputType] = useState(false);
    const [imageBrightness, setImageBrightness] = useState(defaultImageBrightness);
    const [exampleImages, setExampleImages] = useState([]);

    const modelerRef = useRef(null); 

    useEffect(() => {

        /**
         * Creates bpmn-js modeler
         */
        const createModeler = () => {

            $('#bpmn-container').hide();

            //Removing these disables snapping and makes annotation more precise
            //For some reason not possible with additionalModules parameter
            BpmnModeler.prototype._modules = BpmnModeler.prototype._modules.filter(function(module) {
                return (
                    //Remove GridSnappingModule (limits annotations to specific grids)
                    !module.connectSnapping && !module.createMovingSnapping 
                    //Remove SnappingModule (limits annotations to specific grids)
                    && !module.bpmnGridSnapping
                );
            });

            modelerRef.current = new BpmnModeler({
                container: '#bpmn-modeler',
                bpmnRenderer: {
                    defaultStrokeColor: diagramStrokeColor, 
                    defaultFillColor: 'none', // Activating this causes filled Message Event to be displayed incorrectly
                },
                additionalModules: [
                    { 
                    moveCanvas: ['value', null], //disables moving diagram by dragging
                    zoomScroll: ['value', null],  //disables moving diagram and zooming by scrolling
                    autoScroll: ['value', null]  //disables moving diagram when user e.g. resizes element out of border
                    },
                    customBpmnModules
                ],
                textRenderer: {
                    defaultStyle: {
                        fontSize: diagramFontSize,
                        fontWeight: "bold"
                    }
                }
            });
        }

        /**
         * Fetch example images at the start
         */
        const fetchExampleImages = async () => {
            const imageFiles = [];

            for (let imgUrl of exampleImageUrls) {
                const fileName = imgUrl.replace(/^.*[\\/]/, '')

                await fetch(imgUrl).then(response => response.blob()).then(blob => {
                    imageFiles.push({ file: new File([blob], fileName, {type: "image/jpeg"}), url: imgUrl });
                })
            }
            setExampleImages(imageFiles)
        }

        if (modelerRef.current === null) {
            createModeler();
        }

        if (exampleImages.length < exampleImageUrls.length) {
            fetchExampleImages()
        }

    }, [exampleImages]);

    /**
     * Updates image file and resets depending values
     * 
     * @param {File | null} newImageFile 
     */
    const updateImageFile = (newImageFile) => {

        if (imageFile !== newImageFile) {
            setImageFile(newImageFile);

            //new image file was added or cleared, therefore reset everything else
            setBpmnFileReceived(false);
            setImageSubmitted(false);
            setWrongInputType(false);
            setImageBrightness(defaultImageBrightness);

            //reset modeler
            let backgroundImg = document.getElementById("background-img");
            backgroundImg.src = "";
            backgroundImg.style.filter = 'brightness(' + defaultImageBrightness + '%)';
            $('#bpmn-container').hide();
        }
    } 

    /**
     * Submits image to backend after resizing it 
     */
    const onSubmitImage = async () => {

        if (imageFile === null) return;

        setImageSubmitted(true);

        const resizedImageFile = await resizeImage(imageFile, maxImgWidthOrHeight);

        let formData = new FormData();
        formData.append('file', resizedImageFile);

        //Post image to backend
        fetch(baseUrl + '/bpmn-xml', {
            method: 'POST',
            body: formData
        }).then(response => response.text()).then(text => {
            _handleBackendResponse(resizedImageFile, text);
        });
    }

    /**
     * Downloads current BPMN file from modeler
     */
    const downloadBpmnFile = () => {
        modelerRef.current.saveXML({ format: true }).then(response => {
            let currentBpmnFile = new Blob([response.xml], {type: 'application/xml'});
            downloadBlob(currentBpmnFile, getImageName(imageFile) + '.bpmn');
        })
    }

    /**
     * Downloads drawIO file after getting current BPMN file from modeler and sending it to backend for drawIO conversion
     */
    const downloadDrawIOFile = () => {
        modelerRef.current.saveXML({ format: true }).then(response => {
            let currentBpmnFile = new Blob([response.xml], {type: 'application/xml'});

            let formData = new FormData();
            formData.append('file', currentBpmnFile);
  
            //Post BPMN XML to backend and download response as drawIO file
            fetch(baseUrl + '/drawio', {
                method: 'POST',
                body: formData
            }).then(response => response.text()).then(text => {
                let currentDrawIOFile = new Blob([text], {type: 'application/xml'});
                downloadBlob(currentDrawIOFile, getImageName(imageFile) + '.drawio');
            });
        })
    }

    /**
     * Sets background image brightness to value in per cent
     * 
     * @param {number} value 
     */
    const setBackgroundBrightness = (value) => {
        let bpmnContainer = document.getElementById("background-img");
        bpmnContainer.style.filter = 'brightness(' + value + '%)';
        setImageBrightness(value);
    }
  
    /**
     * Handles BPMN XML file response from backend
     * 
     * @param {File} submittedImageFile file that was submitted to backend
     * @param {string} responseText 
     */
    const _handleBackendResponse = (submittedImageFile, responseText) => {
    
        setBpmnFileReceived(true);
        let bpmnXml = responseText;

        //set background image
        let backgroundImg = document.getElementById("background-img");
        backgroundImg.src = URL.createObjectURL(submittedImageFile);
        backgroundImg.style.width = 'auto';
    
        backgroundImg.onload = () => {

            if (backgroundImg.width === 0 || backgroundImg.height === 0) {
                console.error("background width and height need to be greater than 0!");
                return;
            }

            let bpmnContainer = document.getElementById("bpmn-container");
            let bpmnModeler = document.getElementById("bpmn-modeler");
            let padding = parseInt(window.getComputedStyle(bpmnContainer).padding);
            let maxPossibleWidth = window.innerWidth - (2 * padding) - paletteWidth;

            //resize background and diagram if window width is too small for background width (mostly equals maxImgWidthOrHeight)
            if (maxPossibleWidth < backgroundImg.width) {
                let originalBackgroundWidth = backgroundImg.width;
                backgroundImg.style.width = maxPossibleWidth + 'px';
                let scaleFactor = backgroundImg.width / originalBackgroundWidth;
                bpmnXml = scaleBpmnXml(bpmnXml, scaleFactor);
            }

            bpmnModeler.style.height = backgroundImg.height + "px";
            modelerRef.current.importXML(bpmnXml);
        }

        $('#bpmn-container').show();
    }

    return (
        <Router>
        <Header />
        <div className='content'>
            <Routes>
                <Route 
                    path='/' 
                    element={
                        <Homepage 
                            updateImageFile={updateImageFile}
                            imageFile={imageFile}
                            imageSubmitted={imageSubmitted}
                            bpmnFileReceived={bpmnFileReceived}
                            onSubmitImage={onSubmitImage}
                            downloadBpmnFile={downloadBpmnFile}
                            wrongInputType={wrongInputType}
                            setWrongInputType={setWrongInputType}
                            exampleImages={exampleImages}
                            imageBrightness={imageBrightness}
                            setBackgroundBrightness={setBackgroundBrightness}
                            downloadDrawIOFile={downloadDrawIOFile}
                        />
                    } 
                />
                <Route path='/about' element={<About />} />
                <Route path='*' element={<PageNotFound />} />
                {/* Other Routes */}
            </Routes>
        </div>
        {/* Footer */}
        </Router>
    );
}

export default App;